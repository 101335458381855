import { getToken } from './AuthStorage'

export type Method = 'get' | 'post' | 'put' | 'delete';

const apiClient = {

    async fetch(method: Method, url: string, data?: any) {
        const headers: HeadersInit = { 'Content-Type': 'application/json' };
        const token = getToken()
        if (token)
            headers['Authorization'] = `Bearer ${token}`

        const requestData: RequestInit = {
            method,
            headers
        };
        if ((method === 'post' || method === 'put') && data !== undefined)
            requestData.body = JSON.stringify(data);

        const response = await fetch(url, requestData);
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1)
            var responseJson = await response.json();

        if (response.ok) {
            if (responseJson)
                return responseJson;
        }
        else if (response.status === 401) {
            throw new Error(`You don't have permissions to this resource`);
        }
        else if (response.status === 403) {
            throw new Error(`You don't have permissions to this resource`);
        }
        else if (response.status >= 400 && response.status < 500) {
            throw new Error(responseJson.message ?? 'An error occurred');
        }
        else {
            throw new Error('An error occurred');
        }
    },

    get(url: string) {
        return this.fetch('get', url);
    },

    post(url: string, data?: any) {
        return this.fetch('post', url, data);
    },

    put(url: string, data: any) {
        return this.fetch('put', url, data);
    },

    delete(url: string) {
        return this.fetch('delete', url);
    },
}

export default apiClient;
