import React from 'react';
import { Container } from 'react-bootstrap';
import { NavMenu } from './NavMenu';

export function Layout(props: { children: React.ReactNode }) {

  return (
    <>
      <NavMenu />
      <Container fluid className="d-flex flex-column"
        style={{ minHeight: 'calc(100% - 3rem)' }}>
        {props.children}
      </Container>
    </>
  );
}
