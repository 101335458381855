import React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Reflection, metricNames } from './Types';
import AutoPaginationTable from './general/AutoPaginationTable';
import { ColumnDef } from '@tanstack/react-table';
import { useFetchOperation } from '../dataAccess/Operation';
import Loader from './general/Loader';
import Error from './general/Error';
import { Link } from 'react-router-dom';

export default function History() {

  const [reflections, setReflections] = React.useState<Reflection[]>();
  const [getting, startGetting] = useFetchOperation(setReflections, undefined, true);

  React.useEffect(() => {
    startGetting('get', `api/reflection/history`);
  }, [])

  return (
    <>
      <h1 className="mt-3 mb-0">
        Reflection history
      </h1>

      {getting.error ?
        <Error text={getting.error} />
        :
        getting.active ?
          <Loader />
          :
          reflections?.length ?
            <AutoPaginationTable
              className="my-3"
              columns={columns}
              data={reflections} />
            :
            <p>No history found</p>
      }
    </>
  )
}

const columns: ColumnDef<Reflection>[] = [
  {
    header: 'Date',
        accessorFn: r => r.date,
        cell: props => <Link to={'/?date=' + props.row.original.date} className="mx-2 mx-sm-3">{props.row.original.date.replaceAll('-', '‑')}</Link>
  },
  {
    header: metricNames.overallScore,
    accessorFn: r => r.overallScore
  },
  {
    header: metricNames.outdoorDuration,
    accessorFn: r => r.outdoorDuration
  },
  {
    header: metricNames.sunBrightness,
    accessorFn: r => r.sunBrightness
  },
  {
    header: metricNames.sunExposure,
    accessorFn: r => r.sunExposure
  },
  {
    header: metricNames.outdoorIntensity,
    accessorFn: r => r.outdoorIntensity
  },
  {
    header: metricNames.overheat,
    accessorFn: r => r.overheat
  },
  {
    header: metricNames.problemDuration,
    accessorFn: r => r.problemDuration
  },
  {
    header: metricNames.problemIntensity,
    accessorFn: r => r.problemIntensity
  },
];