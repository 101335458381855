import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { removeToken } from './../dataAccess/AuthStorage';

export function NavMenu() {

  const [isExpanded, setIsExpanded] = React.useState(false);

  function setNavExpanded(expanded: boolean) {
    setIsExpanded(expanded);
  }

  function closeNav() {
    setIsExpanded(false);
  }

  function logOut() {
    removeToken();
    window.location.reload();
  }

  return (
    <header>
      <Navbar collapseOnSelect
        bg="primary"
        variant="dark"
        className="box-shadow py-1"
        onToggle={setNavExpanded}
        expanded={isExpanded}> {/* expand="sm"*/}

        <Container fluid>
          <Navbar.Brand
            as={Link}
            to="/"
            onClick={closeNav}
            className="me-0">
            Wellbeing
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav"
            className="ms-1" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">

              <Nav.Link as={Link} to="/history" onClick={closeNav}>History</Nav.Link>

              <Nav.Link as={Link} to="/report" onClick={closeNav}>Report</Nav.Link>

              <Nav.Link as={Link} to="/" onClick={logOut}>Log out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
