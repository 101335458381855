import { getToken, getSignInUrl } from '../dataAccess/AuthStorage';
import { Wellbeing } from './Wellbeing';

export default function Home() {
    const token = getToken();
    const signInUrl = getSignInUrl();

    return (
        <>
            {!token ?
                <div>
                    <a href={signInUrl}>Sign In with Google account</a>
                </div>
                :
                <Wellbeing />
            }
        </>
    );
}

