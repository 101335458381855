import React from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import Moment from 'react-moment';
import { Reflection, Options, ReflectionData, metricNames } from './Types';
import { useLocation, useSearchParams } from 'react-router-dom';
import Loader from './general/Loader';
import Error from './general/Error';
import { useFetchOperation } from '../dataAccess/Operation';

export function Wellbeing() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [date, setDate] = React.useState(new Date());
  const [reflection, setReflection] = React.useState<Reflection>();
  const [options, setOptions] = React.useState<Options>();

  const [getting, startGetting] = useFetchOperation(onGettingSuccess, undefined, true);
  const [editing, startEditing, resetEditing] = useFetchOperation();

  React.useEffect(() => {
    var sp = searchParams.get('date');
    const newDate = sp ? new Date(sp) : new Date();
    setDate(newDate);
    resetEditing();
    setReflection(undefined);
    setOptions(undefined);
    var formatted = newDate.toISOString().split('T')[0];
    startGetting('get', `api/reflection?date=${formatted}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function onGettingSuccess(data: ReflectionData) {
    setReflection(data.reflection);
    setOptions(data.options);
  }

  function setMetric(property: string, value: number | undefined) {
    const refl = { ...reflection, [property]: value }
    setReflection(refl as any);
    resetEditing();
  }

  function onSaveClick() {
    startEditing('post', 'api/reflection', reflection);
  }

  function decreaseDate() {
    resetEditing();
    setReflection(undefined);
    setOptions(undefined);
    var newDate = new Date(date.setDate(date.getDate() - 1)).toISOString().split('T')[0];
    setSearchParams([['date', newDate]])
  }

  function increaseDate() {
    resetEditing();
    setReflection(undefined);
    setOptions(undefined);
    var newDate = new Date(date.setDate(date.getDate() + 1)).toISOString().split('T')[0];
    setSearchParams([['date', newDate]])
  }

  return (
    <>
      <h1 className="mt-3 mb-0">
        <Moment date={date}
          format="dddd, YYYY-MM-DD" />
        {date.getDate() === new Date().getDate() &&
          <span> (today)</span>
        }
      </h1>

      <div className="my-3">
        <Button variant="outline-primary"
          className="px-4"
          id="button-addon2"
          onClick={decreaseDate} >
          {'<<'}
        </Button>

        <Button variant="outline-primary"
          className="mx-3 px-4"
          id="button-addon2"
          onClick={increaseDate} >
          {'>>'}
        </Button>

        <Button className="px-4"
          onClick={onSaveClick}>
          {editing.success ? 'Saved' : 'Save'}
        </Button>
      </div>

      {getting.error ?
        <Error text={getting.error} />
        :
        getting.active ?
          <Loader />
          :
          (reflection && options) ?
            <div>
              <MetricLine name={metricNames.overallScore}
                value={reflection.overallScore}
                options={options.overallScore}
                setValue={m => setMetric('overallScore', m)} />

              <MetricLine name={metricNames.outdoorDuration}
                value={reflection.outdoorDuration}
                options={options.outdoorDuration}
                setValue={m => setMetric('outdoorDuration', m)} />

              <MetricLine name={metricNames.sunBrightness}
                value={reflection.sunBrightness}
                options={options.sunBrightness}
                setValue={m => setMetric('sunBrightness', m)} />

              <MetricLine name={metricNames.sunExposure}
                value={reflection.sunExposure}
                options={options.sunExposure}
                setValue={m => setMetric('sunExposure', m)} />

              <MetricLine name={metricNames.outdoorIntensity}
                value={reflection.outdoorIntensity}
                options={options.outdoorIntensity}
                setValue={m => setMetric('outdoorIntensity', m)} />

              <MetricLine name={metricNames.overheat}
                value={reflection.overheat}
                options={options.overheat}
                setValue={m => setMetric('overheat', m)} />

              <MetricLine name={metricNames.problemDuration}
                value={reflection.problemDuration}
                options={options.problemDuration}
                setValue={m => setMetric('problemDuration', m)} />

              <MetricLine name={metricNames.problemIntensity}
                value={reflection.problemIntensity}
                options={options.problemIntensity}
                setValue={m => setMetric('problemIntensity', m)} />
            </div>
            :
            <p>Unknown error</p>
      }
    </>
  );
}

function MetricLine(props: {
  name: string,
  value: number,
  options: number[],
  setValue: (v: number) => void
}) {
  function decrease() {
    var index = props.options.indexOf(props.value);
    index--;
    if (index < 0)
      index = 0;

    props.setValue(props.options[index]);
  }

  function increase() {
    var index = props.options.indexOf(props.value);
    index++;
    if (index > props.options.length - 1)
      index = props.options.length - 1;

    props.setValue(props.options[index]);
  }

  return (
    <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1"
        className="flex-grow-1">{props.name}</InputGroup.Text>

      <Button variant="outline-secondary"
        id="button-addon2"
        onClick={decrease} >
        {'<<'}
      </Button>

      <InputGroup.Text id="basic-addon1"
        className="fw-bold fs-5 text-center d-block"
        style={{ minWidth: '60px' }} >
        <span className="">{props.value}</span>
      </InputGroup.Text>

      <Button variant="outline-secondary"
        id="button-addon2"
        onClick={increase} >
        {'>>'}
      </Button>
    </InputGroup>
  )
}
