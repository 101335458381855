import apiClient from './ApiClient';

const tokenStorageKey = 'token';

let token: string | undefined = undefined;

export interface User {
    token: string,
    email: string,
}

interface TokenData {
    nameid: number,
    name: string,
    email: string,
    role: string[],
    exp: number,
    nbf: number
}

export function getToken(): string | undefined {
    if (!token) {
        const storedToken = localStorage.getItem(tokenStorageKey);
        if (storedToken) {
            const tokenData: TokenData = JSON.parse(atob(storedToken.split('.')[1]));
            if (tokenData.exp > new Date().getTime() / 1000) {
                token = storedToken

                if ((tokenData.exp - new Date().getTime() / 1000) < (tokenData.exp - tokenData.nbf) / 2) {
                    apiClient.post('/api/auth/refresh-token').then(data => {
                        localStorage.setItem(tokenStorageKey, data.token);
                    });
                }
            }
            else
                localStorage.removeItem(tokenStorageKey);
        }
    }
    return token;
}

export function setToken(newToken: string) {
    localStorage.setItem(tokenStorageKey, newToken);
    token = newToken;
}

export function removeToken() {
    localStorage.removeItem(tokenStorageKey);
    token = undefined;
}

export function getSignInUrl() {
    var current = window.location.href;
    if (!current.endsWith('/'))
        current += '/';
    return `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${current}auth&client_id=622618939458-brdg3i57am42mf5pr1qdtuhd8a8qqhk8.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/userinfo.email`;
}
