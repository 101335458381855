export interface ReflectionData {
  options: Options;
  reflection: Reflection;
}

export interface Reflection {
  date: string;
  overallScore: number;
  outdoorDuration: number;
  sunBrightness: number;
  sunExposure: number;
  outdoorIntensity: number;
  overheat: number;
  problemDuration: number;
  problemIntensity: number;
}

export interface Options {
  overallScore: number[];
  outdoorDuration: number[];
  sunBrightness: number[];
  sunExposure: number[];
  outdoorIntensity: number[];
  overheat: number[];
  problemDuration: number[];
  problemIntensity: number[];
}

export interface Report {
  outdoorHours: number,
  outdoorDays: number,
  intensiveOutdoorHours: number,
  intensiveOutdoorDays: number,
  problemHours: number,
  problemDays: number,
  intensiveProblemHours: number,
  intensiveProblemDays: number,
}

export const metricNames = {
  overallScore: 'Overall score, %',
  outdoorDuration: 'Outdoor duration, min',
  sunBrightness: 'Sun brightness, %',
  sunExposure: 'Sun exposure, % of time',
  outdoorIntensity: 'Activity intensity, %',
  overheat: 'Overheat, %',
  problemDuration: 'Problem duration, min',
  problemIntensity: 'Problem intensity, %',
}
