import { useState } from 'react';
import Pagination from './Pagination'
import {
    useReactTable,
    getCoreRowModel,
    ColumnDef,
    flexRender,
    SortingState,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
} from '@tanstack/react-table'

export const defaultPageSize = 100;

export default function AutoPaginationTable<D extends object>(props: {
    columns: ColumnDef<D>[],
    data: D[],
    className?: string,
    pageSize?: number
}) {
    const [pageCount] = useState<number>(Math.ceil(props.data.length / (props.pageSize ?? defaultPageSize)));
    const [sorting, setSorting] = useState<SortingState>([])
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: (props.pageSize ?? defaultPageSize)
    });

    const table = useReactTable({
        data: props.data ?? [],
        columns: props.columns,
        pageCount,
        state: {
            sorting,
            pagination
        },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    })

    return (
        <div className={'w-100 ' + (props.className || '')}>
            <Pagination className="mb-3"
                currentPage={table.getState().pagination.pageIndex + 1}
                pageCount={pageCount}
                totalItemsCount={props.data.length}
                goToPage={(x) => table.setPageIndex(x - 1)} />

            <div className="table-responsive">
                <table className="table table-striped table-bordered table-sm text-center">
                    <thead className="table-secondary align-middle">
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th key={header.id}
                                            colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    {...{
                                                        className: header.column.getCanSort()
                                                            ? 'cursor-pointer user-select-none'
                                                            : '',
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: ' 🔼',
                                                        desc: ' 🔽',
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                </div>
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>

                    <tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <Pagination className="mt-3"
                currentPage={table.getState().pagination.pageIndex + 1}
                pageCount={pageCount}
                totalItemsCount={props.data.length}
                goToPage={(x) => table.setPageIndex(x - 1)} />
        </div>
    )
}
