import Home from "./components/Home";
import History from "./components/History";
import Report from "./components/Report";
import SignInGoogle from "./components/SignInGoogle";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
      path: '/history',
        element: <History/>
    },
    {
      path: '/report',
        element: <Report />
    },
    {
        path: '/auth',
        element: <SignInGoogle />
    },
];

export default AppRoutes;
