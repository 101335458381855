import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import apiClient from '../dataAccess/ApiClient';
import { setToken } from '../dataAccess/AuthStorage';

export default function SignInGoogle() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code")
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    apiClient.post('api/auth', { code: code })
      .then(data => {
        setToken(data.token);
        navigate('/');
      }).catch(e => {
        console.log(e);
        setError(e.message);
      });

  }, [code])
  return <div>
    Signing in...
    <br />
    <div>{error}</div>
  </div>
}
