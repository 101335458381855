import React from 'react';
import { Button, Table } from 'react-bootstrap';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, Range } from 'react-date-range';
import { useFetchOperation } from '../dataAccess/Operation';
import { useLocation, useSearchParams } from 'react-router-dom';
import Loader from './general/Loader';
import Error from './general/Error';

interface Report {
  outdoorHours: number,
  outdoorDays: number,
  intensiveOutdoorHours: number,
  intensiveOutdoorDays: number,
  problemHours: number,
  problemDays: number,
  intensiveProblemHours: number,
  intensiveProblemDays: number,
}

export default function Report() {

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [report, setReport] = React.useState<Report>();
  const [getting, startGetting] = useFetchOperation(setReport);

  const defaultRange: Range = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  if (start && end) {
    defaultRange.startDate = new Date(start);
    defaultRange.endDate = new Date(end);
  }
  const [range, setRange] = React.useState<Range>(defaultRange);

  React.useEffect(() => {
    const start = searchParams.get('start');
    const end = searchParams.get('end');
    if (start && end) {
      setRange({
        startDate: new Date(start),
        endDate: new Date(end),
        key: 'selection',
      });
      startGetting('get', `api/report?startDate=${range.startDate?.toDateString()}&endDate=${range.endDate?.toDateString()}`);
    }
    else {
      setRange(defaultRange);
      setReport(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function onRangeChange(r: { selection: Range }) {
    setRange(r.selection);
  }

  async function getReport() {
    const sp: [string, string][] = [
      ['start', range.startDate!.toISOString().split('T')[0]],
      ['end', range.endDate!.toISOString().split('T')[0]],
    ];
    setSearchParams(sp)
  }

  return (
    <>
      <h1 className="mt-3 mb-0">
        Generate report
      </h1>

      <DateRangePicker className="my-3"
        ranges={[range]}
        onChange={onRangeChange as any} />

      <div>
        <Button className="mb-3"
          onClick={getReport}
          style={{ minWidth: '150px' }} >
          Get report
        </Button>
      </div>

      {getting.error ?
        <Error text={getting.error} />
        :
        getting.active ?
          <Loader />
          :
          report ?
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Total</th>
                  <th>Intensive</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Outdoor hours</td>
                  <td>{(Math.round(report.outdoorHours * 10)) / 10}</td>
                  <td>{(Math.round(report.intensiveOutdoorHours * 10)) / 10}</td>
                </tr>
                <tr>
                  <td>Problem hours</td>
                  <td>{(Math.round(report.problemHours * 10)) / 10}</td>
                  <td>{(Math.round(report.intensiveProblemHours * 10)) / 10}</td>
                </tr>
                <tr>
                  <td>Outdoor days</td>
                  <td>{report.outdoorDays}</td>
                  <td>{report.intensiveOutdoorDays}</td>
                </tr>
                <tr>
                  <td>Problem days</td>
                  <td>{report.problemDays}</td>
                  <td>{report.intensiveProblemDays}</td>
                </tr>
              </tbody>
            </Table>
            :
            <></>
      }
    </>
  )
}

