import { Button, InputGroup } from "react-bootstrap";

export default function Pagination(props: {
    currentPage: number,
    pageCount: number,
    totalItemsCount: number
    goToPage: (p: number) => void,
    className?: string
}) {
    const { currentPage, pageCount } = props;

    return (
        <>
            {pageCount > 1 &&
                <InputGroup
                    className={"pagination-block justify-content-center " + (props.className || '')}>

                    <Button variant="outline-primary" data-bs-toggle="tooltip"
                        className="fw-bold"
                        disabled={currentPage === 1}
                        onClick={() => props.goToPage(1)}>&laquo;</Button>

                    <Button variant="outline-primary"
                        disabled={currentPage === 1}
                        onClick={() => props.goToPage(currentPage - 1)}>Prev</Button>

                    <InputGroup.Text id="btnGroupAddon" >
                        Page {props.currentPage}&thinsp;/&thinsp;{props.pageCount}
                    </InputGroup.Text>

                    <Button variant="outline-primary"
                        disabled={currentPage === pageCount}
                        onClick={() => props.goToPage(currentPage + 1)}>Next</Button>

                    <Button variant="outline-primary" data-bs-toggle="tooltip"
                        className="fw-bold"
                        disabled={currentPage === pageCount}
                        onClick={() => props.goToPage(pageCount)}>&raquo;</Button>

                </InputGroup>
            }
        </>
    )
}
